import React, { FC } from 'react'
import { graphql, navigate } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import sanitizeHtml from 'sanitize-html'
import TechnologyData from 'content/technology.json'
import Layout from 'components/Layout'
import FAQ from 'components/FAQ'
import MainForm from 'components/Form/MainForm'
import Hero from 'components/Hero'
import Icons from 'components/Icons'
import { getImage } from 'helpers/getImage'
import { scrollToSection } from 'helpers/scroll'
import MockupGIF from 'assets/images/technology/sections/mockup.gif'
import {
  Container,
  SimpleGrid,
  Box,
  Heading,
  Button,
  Text,
  Flex,
  Img,
  AspectRatio,
} from '@chakra-ui/react'

type TechnologyPageParams = {
  data: any
}

const TechnologyPage: FC<TechnologyPageParams> = ({ data }) => {
  const { t, i18n } = useTranslation()
  const { sections, features } = TechnologyData
  const images = data
  const homePath = i18n.language === 'fr' ? '/fr' : '/'

  return (
    <Layout>
      <Hero
        title={t('technology.hero.title')}
        description={t('technology.hero.subtitle')}
        button={
          <Flex flexDir={['column', 'row']}>
            <Button
              size="lg"
              variant="light"
              leftIcon={<Icons name="arrow-right-circle" />}
              iconSpacing="0.75rem"
              mr={[0, 3]}
              mb={[3, 0]}
              onClick={() =>
                navigate(homePath, {
                  state: {
                    scrollTo: 'home-features',
                  },
                })
              }
            >
              {t('technology.hero.buttonSoftware')}
            </Button>
            <Button
              size="lg"
              variant="primary"
              onClick={() => scrollToSection('main-form')}
            >
              {t('technology.hero.buttonTrial')}
            </Button>
          </Flex>
        }
        image={
          <StaticImage
            style={{ height: '100%' }}
            src="../assets/images/technology/hero.png"
            alt="technology hero"
            placeholder="blurred"
          />
        }
      />
      <Container py={['4rem', '6rem']}>
        {sections.map((section, idx) => (
          <SimpleGrid
            key={idx}
            columns={[1, 1, 2]}
            spacing={['2.5rem', '6rem']}
            gridAutoFlow="dense"
            alignItems="center"
            sx={{
              direction: section.alignRight ? 'rtl' : 'ltr',
              '&:not(:last-child)': {
                pb: ['3rem', '6rem'],
              },
            }}
          >
            <Box textAlign="left" sx={{ direction: 'ltr' }}>
              <Text color="blue.400" fontWeight="semibold" mb="0.75rem">
                {t(`technology.sections.${idx}.subHeading`)}
              </Text>
              <Heading as="h2" mb={['1rem', '2.5rem']}>
                {t(`technology.sections.${idx}.title`)}
              </Heading>
              <Text
                size="lg"
                color="gray.400"
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(t(`technology.sections.${idx}.text`)),
                }}
              />
              {section.brands && (
                <SimpleGrid
                  display={section.brands.length > 4 ? 'grid' : 'flex'}
                  columns={section.brands.length > 4 ? 4 : undefined}
                  mt={['1.5rem', '2.5rem']}
                  spacing="0.75rem"
                  alignItems="center"
                >
                  {section.brands.map((brand, idx) => (
                    <Box key={idx} mr={section.brands.length < 4 ? '2rem' : 0}>
                      <GatsbyImage
                        image={getImage(images.sections.nodes, brand)}
                        objectFit="contain"
                        style={{
                          width: '100%',
                          height: '100%',
                          maxWidth: '130px',
                          maxHeight: brand === 'aws' ? '30px' : '50px',
                        }}
                        alt={brand}
                      />
                    </Box>
                  ))}
                </SimpleGrid>
              )}
              {/* {section.link && (
                <Link
                  href={section.link}
                  isExternal
                  display="block"
                  mt="2rem"
                  variant="primary"
                >
                  <Button variant="primary">
                    {t(`technology.sections.${idx}.link`)}
                    <Box as="span" ml="0.5rem">
                      <Icons name="arrow-right" />
                    </Box>
                  </Button>
                </Link>
              )} */}
            </Box>
            {idx === 0 ? (
              <AspectRatio ratio={1}>
                <Img
                  src={MockupGIF}
                  alt="gif mockup"
                  borderRadius={['0.5rem', '4rem']}
                  objectFit="cover"
                  height={{ base: '420px', sm: 'auto' }}
                />
              </AspectRatio>
            ) : idx === 1 ? (
              <AspectRatio ratio={1}>
                <iframe
                  scrolling="no"
                  src="https://infrastructuremap.microsoft.com/explore#main"
                  width="100%"
                  height="100%"
                />
              </AspectRatio>
            ) : (
              <AspectRatio
                ratio={1 / 1}
                sx={{
                  '.gatsby-image-wrapper': {
                    borderRadius: ['0.5rem', '4rem'],
                  },
                }}
              >
                <GatsbyImage
                  alt={`Techonology image ${section.image}`}
                  image={getImage(images.sections.nodes, section.image)}
                  style={{ height: '100%' }}
                />
              </AspectRatio>
            )}
          </SimpleGrid>
        ))}
      </Container>
      <Box bg="blue.100">
        <Container>
          <Box py={['4rem', '6rem']} textAlign="center">
            <Heading>{t('technology.features.title')}</Heading>
            <SimpleGrid
              columns={[1, 2, 2, 3]}
              spacingX="2rem"
              spacingY={['2rem', '4.5rem']}
              mt="4rem"
            >
              {features.map((item, idx) => (
                <Box
                  key={idx}
                  bg="gray.50"
                  borderRadius="1rem"
                  padding="3.5rem 1.5rem"
                  position="relative"
                  sx={{
                    '&:not(:last-child)': {
                      mb: { base: '2.5rem', md: 0, xl: 0 },
                    },
                    mb: { base: '2.5rem', md: 0, xl: 0 },
                  }}
                >
                  <Box
                    position="absolute"
                    top="-2rem"
                    left="50%"
                    transform="translateX(-50%)"
                  >
                    <GatsbyImage
                      alt={item}
                      image={getImage(images.features.nodes, item)}
                      style={{ width: '4rem', height: '4rem' }}
                    />
                  </Box>
                  <Text size="xl" fontWeight="500" color="black.400">
                    {t(`technology.features.list.${idx}.title`)}
                  </Text>
                  <Text color="gray.400" mt="2">
                    {t(`technology.features.list.${idx}.text`)}
                  </Text>
                </Box>
              ))}
            </SimpleGrid>
          </Box>
        </Container>
      </Box>
      <FAQ
        title={t('earlyAdopters.faq.title')}
        subtitle={t('earlyAdopters.faq.subtitle')}
        data={t('earlyAdopters.faq.list', { returnObjects: true }) || []}
      />
      <MainForm
        title={t('earlyAdopters.form.title')}
        subtitle={t('earlyAdopters.form.subtitle')}
      />
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    sections: allFile(
      filter: {
        relativeDirectory: { eq: "technology/sections" }
        extension: { eq: "png" }
      }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
        name
      }
    }
    features: allFile(
      filter: { relativeDirectory: { eq: "technology/features" } }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
        name
      }
    }
  }
`

export default TechnologyPage
