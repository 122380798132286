import React, { FC, ReactNode } from 'react'
import { SimpleGrid, Box, Heading, Text, Container } from '@chakra-ui/react'

type HeroParams = {
  title: string
  description: string
  button: ReactNode
  image: ReactNode
}

const Hero: FC<HeroParams> = ({ title, description, button, image }) => {
  return (
    <Box
      bg="black.600"
      color="white"
      mt={{ base: '4.25rem', md: '0' }}
      position="relative"
    >
      <Container paddingRight={['1rem', '1rem', 0, 0, 0, 0]}>
        <Box pt={{ base: '4rem', md: '0' }} minHeight="100vh" display="flex">
          <SimpleGrid
            columns={[1, 1, 2]}
            alignItems="center"
            spacing={[8, 8, '7rem']}
            pb={[4, 4, 0]}
          >
            <Box>
              <Heading as="h1" size="lg">
                {title}
              </Heading>
              <Text size="xl" mt="6">
                {description}
              </Text>
              <Box mt="12">{button}</Box>
            </Box>
            <Box
              ml="auto"
              mr={['auto', 'auto', 0]}
              sx={{
                height: '100%',
                position: { base: 'static', lg: 'absolute' },
                top: 0,
                right: 0,
                width: { lg: 'calc(50% - 6rem)' },
              }}
            >
              {image}
            </Box>
          </SimpleGrid>
        </Box>
      </Container>
    </Box>
  )
}
export default Hero
